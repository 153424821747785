import Head from 'next/head'
import styles from '@/styles/Home.module.scss'
import { ArrowheadScroller } from '@/components/arrowhead-scroller'
import { useRef } from 'react'
import { YouTubeVideo } from '@/components/youtube-video'
import { Hero } from '@/components/hero'
import { NavigationButtons } from '@/components/navigation-buttons'
import { YOUTUBE_VIDEOS } from '@/data/data'

export default function Home() {
    const firstLinkRef = useRef<HTMLElement>(null)
    return (
        <>
            <Head>
                <title>Texas Paleoindian</title>
                <link
                    rel="preload"
                    href="/images/hero/hero@2x.webp"
                    as="image"
                />
                <link rel="preload" href="/images/hero/hero.webp" as="image" />
                <link
                    rel="preload"
                    href="/images/hero/hero@4x.webp"
                    as="image"
                />
            </Head>
            <section className={styles.main}>
                <Hero
                    onClick={() =>
                        firstLinkRef.current?.scrollIntoView({
                            behavior: 'smooth',
                        })
                    }
                />
                <section className={styles.intro} ref={firstLinkRef}>
                    <p className={styles.description}>
                        When mammoths, sabertoothed cats, and giant sloths
                        roamed Texas, people were there too.
                    </p>
                    <p className={styles.details}>
                        This online exhibit presents cutting edge science and
                        ancient wisdom to explore what life was like for the
                        first people to call Texas home.
                    </p>
                </section>
                <div className={styles.videoSection} id="from-the-top">
                    <YouTubeVideo
                        className={styles.video}
                        {...YOUTUBE_VIDEOS['from-the-top']}
                    />
                    <NavigationButtons />
                </div>
            </section>
            <ArrowheadScroller scrollTarget={firstLinkRef} />
        </>
    )
}
